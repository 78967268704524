import request from '@/utils/request'

export function getVideo(type, size, num) {
    // if (size === undefined || size === '') size = 0;
    if (num === undefined || num === '') num = 1;
    return request({
        //url: '/siteweb/video/list/' + type + "?pageSize=" + size + "&pageNum=" + num,
        url: '/siteweb/video/list?' + "pageSize=" + size + "&pageNum=" + num,
        method: 'get'
    })
}

export function getArticleById(id) {
    return request({
        url: '/siteweb/article/' + id,
        method: 'get'
    })
}

export function getMp() {
    return request({
        url: 'wechat/mp/article/get/list',
        method: 'get'
    })
}
