<template>
  <div>
    <banner choose-int="1506061576485822465"></banner>
    <bread :jup-path="'/'+pagePath" :jup-page="pageTitle"></bread>
    <div class="video-top">
      <div class="con w1440">
          <div class="video-list">
              <ul class="clearfix search-result-list">
                  <li  v-for="(item,index) in videoList" :key="index" class="wow fadeInUp slow animated" style="visibility: visible; animation-name: fadeInUp;" @click="playVedio(item,index)">
                          <div class="top">
                              <img :src="item.thumbnail" alt="">
                              <div class="mask">
                                  <span class="iconfont"></span>
                              </div>
                          </div>
                          <div class="bottom">
                              <h2>{{ item.videoName }}</h2>
                              <div class="txt">
                                  <h3>
                                      <span class="iconfont"></span><span>{{ dataTime(item.createTime) }}</span>
                                  </h3>
                              </div>
                          </div>
                  </li>
              </ul>
              <!--修改结束 -->
          </div>
          <div class="page-bar clearfix">
              <ul>
                  <li v-if="cur>1"><a v-on:click="cur--,pageClick()">上一页</a></li>
                  <li v-if="cur===1"><a class="banClick">上一页</a></li>
                  <li v-for="index in indexList" v-bind:class="{ 'active': cur === index}" :key="index.index">
                      <a v-on:click="btnClick(index)">{{ index }}</a>
                  </li>
                  <li v-if="cur!==all"><a v-on:click="cur++,pageClick()">下一页</a></li>
                  <li v-if="cur === all"><a class="banClick">下一页</a></li>
                  <li><a>共<i>{{ all }}</i>页</a></li>
              </ul>
          </div>

      </div>
    </div>
      <div id="videoPopup" style="display: none;">
          <div class="video-box">
              <video class="vvd" id="vd" src="#" controls=""   autoplay="">This browser does not support HTML5 video</video>
              <div class="close" @click="handleClose()">
                  <span class="iconfont"></span>
              </div>
          </div>
      </div>

    <customFooter></customFooter>
  </div>
</template>

<script>
import customFooter from "@/components/footer/footer";
import Bread from "@/components/bread/Bread";
import Banner from "@/components/banner/Banner";
import {parseTime} from "@/utils/aryee";
import {getVideo} from "@/api/video"


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Video",
  components: {

    customFooter,
    Bread,
    Banner
  },
  data() {
    return {
      videoList: [],
      all: 10, //总页数
      cur: 1,//当前页码
      totalPage: 0,//当前条数
      pagePath: 'video',
      pageTitle: '媒体中心',
      limit: 10
    }
  },
  created() {
      getVideo("video", this.limit, '1').then(res => {
      let limit = this.limit;
      let total = res.data.rows.length;
     this.videoList = res.data.rows;
     //      let oldList=res.data.rows;
         //  let list=new Array();
         //  for(let i=0;i<1;i++){
         //      let obj={
         //          // savePath:'https://static.yngy.cloud/demo/1.mp4',
         //          // videoName:'demoafdsafsdafsdafsdafs',
         //          // videoId:i,
         //          // thumbnail:'https://file.yngy.cloud/statics/siteweb/images/2022/03/23/d9ec4e48-c136-404a-b1f6-f3bf10a9c534.png',
         //          // createTime:'2022-05-15 22:05:56'
         //          savePath:'https://static.yngy.cloud/demo/1.mp4',
         //          videoName:'测试测试是测试测试是测试测试是测试测试是测试测试是测试测试是',
         //          videoId:i,
         //          thumbnail:'https://file.yngy.cloud/statics/siteweb/images/2022/03/23/d9ec4e48-c136-404a-b1f6-f3bf10a9c534.png',
         //          createTime:'2022-05-15 22:05:56'
         //
         //      }
         //      list.push(obj);
         //  }
         //
         // this.videoList=list;
      this.all = total % limit === 0 ? (total / limit) : (Math.floor(total / limit) + 1);
    });
  },
  computed: {
    indexList: function () {
      let left = 1;
      let right = this.all;
      let ar = [];
      if (this.all >= 5) {
        if (this.cur > 3 && this.cur < this.all - 2) {
          left = this.cur - 2
          right = this.cur + 2
        } else {
          if (this.cur <= 3) {
            left = 1
            right = 5
          } else {
            right = this.all
            left = this.all - 4
          }
        }
      }
      while (left <= right) {
        ar.push(left)
        left++
      }
      return ar
    }
  },
  methods: {
    dataTime(value, ft) {
      return parseTime(value, ft);
    },
    dataListFn: function (index) {
      getVideo("video", this.limit, index).then(res => {
        let limit = this.limit;
        let total = res.data.total;
        this.videoList = res.data.rows;
        this.all = total % limit === 0 ? (total / limit) : (Math.floor(total / limit) + 1);
      });
    },
    btnClick: function (data) {
      if (data !== this.cur) {
        this.cur = data
      }
     this.dataListFn(this.cur.toString());
    },
    pageClick: function () {
     this.dataListFn(this.cur.toString());
    },
    playVedio(item,index){
        let playUrl=item.savePath;
        let tag = document.querySelector('#videoPopup');
            tag.style.display='block'
         let child= document.querySelector('#vd');
            child.setAttribute("src",playUrl)
            child.play();
    },
      //关闭弹出框时，视频关闭播放
      handleClose() {
          const video = document.getElementById('vd')
          video.pause()
          let tag = document.querySelector('#videoPopup');
          tag.style.display='none';
      },

  },
}
</script>

<style scoped>
 @import "../../assets/css/news_video.css";
</style>
