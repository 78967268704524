<template>
  <div>
    <Carousel></Carousel>
    <news-node></news-node>
<!--    <service></service>-->
    <product></product>
    <customFooter></customFooter>
  </div>
</template>

<script>
import Carousel from '@/components/carousel/carousel';
import customFooter from "@/components/footer/footer";
import NewsNode from "@/components/news/NewsNode";
import product from "@/components/product/product";
// import Service from "@/components/service/service";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    customFooter,
    Carousel,
    NewsNode,
    product,
  }
}
</script>

<style scoped>

</style>
