<template>
    <div>
        <banner choose-int="1506061759927902210"></banner>
        <bread jup-path="/party" jup-page="党建之窗"></bread>
        <div class="party">
            <div class="con w1440">
                <div class="title clearfix">
                    <div class="left">
                        <h2>品牌阵地</h2>
                    </div>
                    <div class="right clearfix">
                        <span class="new-type" hidden="hidden">13</span>
                        <div class="select-year">
                            <h2 class="current-year">全部</h2>
                            <ul>
                                <li>全部</li>
                                <li>2020</li>
                            </ul>
                        </div>
                        <div class="search-box">
                            <input class="new-search-btn-input search-box-input" type="text" placeholder="请输入关键字搜索">
                            <span class="iconfont new-search-btn search-box-span"></span>
                        </div>
                    </div>        </div>
                <div class="party-list">
                    <ul class="clearfix search-result-list">
                        <li>
                            <a href="news_3637.html" target="_blank">
                                <div class="tu">
                                    <img src="" alt="">
                                </div>
                                <div class="bottom">
                                    <h2>
                                        钢铁先锋党员淬炼线</h2>
                                    <div class="more">
                                        <span class="iconfont"></span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="news_3638.html" target="_blank">
                                <div class="tu">
                                    <img src="" alt="">
                                </div>
                                <div class="bottom">
                                    <h2>

                                        党代表工作室</h2>
                                    <div class="more">
                                        <span class="iconfont"></span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="news_4461.html" target="_blank">
                                <div class="tu">
                                    <img src="" alt="">
                                </div>
                                <div class="bottom">
                                    <h2>

                                        钢铁堡垒支部聚焦点</h2>
                                    <div class="more">
                                        <span class="iconfont"></span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="news_4463.html" target="_blank">
                                <div class="tu">
                                    <img src="" alt="">
                                </div>
                                <div class="bottom">
                                    <h2>
                                        国企新时代文明实践所</h2>
                                    <div class="more">
                                        <span class="iconfont"></span>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <customFooter></customFooter>
    </div>
</template>

<script>
import customFooter from "@/components/footer/footer";
import Bread from "@/components/bread/Bread";
import Banner from "@/components/banner/Banner";
import {getArticle} from "@/api/article";
import {parseTime} from "@/utils/aryee";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "group",
  components: {
    customFooter,
    Bread,
    Banner
  },
  data() {
    return {
      newsList: [],
      all: 10, //总页数
      cur: 1,//当前页码
      totalPage: 0,//当前条数
    }
  },
  created() {
    getArticle("group").then(res => {
      this.newsList = res.data.rows;
      let limit = 10;
      let total = res.data.total;
      this.all = total % limit === 0 ? (total / limit) : (Math.floor(total / limit) + 1);
    });
  },
  computed: {
    indexList: function () {
      let left = 1;
      let right = this.all;
      let ar = [];
      if (this.all >= 5) {
        if (this.cur > 3 && this.cur < this.all - 2) {
          left = this.cur - 2
          right = this.cur + 2
        } else {
          if (this.cur <= 3) {
            left = 1
            right = 5
          } else {
            right = this.all
            left = this.all - 4
          }
        }
      }
      while (left <= right) {
        ar.push(left)
        left++
      }
      return ar
    }
  },
  methods: {
    dataTime(value, ft) {
      return parseTime(value, ft);
    },
    dataListFn: function (index) {
      getArticle("dynamic").then(res => {
        let limit = 10;
        let total = res.data.total;
        this.industryList = res.data.rows;
        this.all = total % limit === 0 ? (total / limit) : (Math.floor(total / limit) + 1);
      });
    },
    btnClick: function (data) {
      if (data !== this.cur) {
        this.cur = data
      }
      this.dataListFn(this.cur.toString());
    },
    pageClick: function () {
      this.dataListFn(this.cur.toString());
    }
  },
}
</script>

<style scoped>
.page-bar {
  margin: 40px auto;
  margin-top: 150px;

}


.page-bar li:first-child > a {
  margin-left: 0
}

.page-bar a {
  border: 1px solid #ddd;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #5D6062;
  cursor: pointer;
  margin-right: 20px;
}

.page-bar a:hover {
  background-color: #eee;
}

.page-bar a.banClick {
  cursor: not-allowed;
}

.page-bar .active a {
  color: #fff;
  cursor: default;
  background-color: #E96463;
  border-color: #E96463;
}

.page-bar i {
  font-style: normal;
  color: #d44950;
  margin: 0px 4px;
  font-size: 12px;
}
</style>
