<template>
  <div>
    <banner choose-int="1506062086005678082"></banner>
    <bread :jup-path="'/'+pagePath" :jup-page="pageTitle"></bread>
    <div class="product">
      <div class="con w1440">
        <ul>
          <li v-for="(item,index) in productList" :key="index">
            <a :href="'javascript:;'" class="clearfix">
              <div class="left wow slideInDown slow">
                <el-image :src="item.savePath" :preview-src-list="[item.savePath]"></el-image>
              </div>
              <div class="right wow slideInUp slow">
                <div class="text">
                  <h2>{{ item.imgName }}</h2>
                  <div class="line"></div>
                  <p>{{item.description}}</p>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <customFooter></customFooter>
  </div>
</template>

<script>
import customFooter from "@/components/footer/footer";
import Bread from "@/components/bread/Bread";
import Banner from "@/components/banner/Banner";
import {getImages} from "@/api/images";
export default {
  name: "productCenter",
  components: {
    customFooter,
    Bread,
    Banner
  },
  data() {
    return {
      productList: [],
      pagePath: 'product',
      pageTitle: '产品中心',
    };
  },
  created() {
    getImages("product").then(res => {
      this.productList = res.data.rows;
    });
  }
}
</script>

<style scoped>

</style>
