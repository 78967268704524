<template>
    <div>
        <banner choose-int="1703998524184436738"></banner>
        <bread jup-path="/produce" jup-page="加工生产"></bread>
        <div class="brief scroll-num">
            <div class="con w1440 clearfix">
                <div class="wow fadeInUp slow animated" style="visibility: visible; animation-name: fadeInUp;">
                    <!--          <h2>{{ aboutUs.title }}</h2>-->
                    <div class="text">
                        <div id="fix" v-html="aboutUs.content"></div>
                    </div>
                </div>
            </div>
        </div>
        <customFooter></customFooter>
    </div>
</template>

<script>
import customFooter from "@/components/footer/footer";
import Bread from "@/components/bread/Bread";
import Banner from "@/components/banner/Banner";
import {getArticleById} from "@/api/article";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Produce",
  components: {
    customFooter,
    Bread,
    Banner
  },
  data() {
    return {
      aboutUs: {}
    };
  },
  created() {
    getArticleById("1701518503750508546").then(res=>{
      this.aboutUs = res.data.data;
    });
  }
}
</script>

<style scoped>

</style>
