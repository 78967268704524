<template>
  <div>
    <banner choose-int="1506061576485822465"></banner>
    <bread :jup-path="'/'+pagePath" :jup-page="pageTitle"></bread>
    <div class="media-top">
      <div class="con w1440">
        <div class="media-list">
          <ul class="search-result-list">
            <li v-for="item in industryList" :key="item.index">
              <router-link class="clearfix"
                           :to="{name:'Details',params:{path:pagePath,title:pageTitle},query:{articleId:item.articleId}}">
                <div class="left">
                  <span>{{ dataTime(item.createTime, "{y}-{m}-{d}") }}</span>
                </div>
                <div class="middle">
                  <h2>{{ item.title }}</h2>
                </div>
                <div class="right">
                  <div class="more">
                    <span class="iconfont">&#xe683;</span>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
          <div class="page-bar clearfix">
            <ul>
              <li v-if="cur>1"><a v-on:click="cur--,pageClick()">上一页</a></li>
              <li v-if="cur===1"><a class="banClick">上一页</a></li>
              <li v-for="index in indexList" v-bind:class="{ 'active': cur === index}" :key="index.index">
                <a v-on:click="btnClick(index)">{{ index }}</a>
              </li>
              <li v-if="cur!==all"><a v-on:click="cur++,pageClick()">下一页</a></li>
              <li v-if="cur === all"><a class="banClick">下一页</a></li>
              <li><a>共<i>{{ all }}</i>页</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <customFooter></customFooter>
  </div>
</template>

<script>
import customFooter from "@/components/footer/footer";
import Bread from "@/components/bread/Bread";
import Banner from "@/components/banner/Banner";
import {getArticle} from "@/api/article";
import {parseTime} from "@/utils/aryee";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Industry",
  components: {
    customFooter,
    Bread,
    Banner
  },
  data() {
    return {
      industryList: [],
      all: 10, //总页数
      cur: 1,//当前页码
      totalPage: 0,//当前条数
      pagePath: 'industry',
      pageTitle: '行业新闻',
      limit: 8
    }
  },
  created() {
    getArticle("industry", this.limit, '1').then(res => {
      let limit = this.limit;
      let total = res.data.total;
      this.industryList = res.data.rows;
      this.all = total % limit === 0 ? (total / limit) : (Math.floor(total / limit) + 1);
    });
  },
  computed: {
    indexList: function () {
      let left = 1;
      let right = this.all;
      let ar = [];
      if (this.all >= 5) {
        if (this.cur > 3 && this.cur < this.all - 2) {
          left = this.cur - 2
          right = this.cur + 2
        } else {
          if (this.cur <= 3) {
            left = 1
            right = 5
          } else {
            right = this.all
            left = this.all - 4
          }
        }
      }
      while (left <= right) {
        ar.push(left)
        left++
      }
      return ar
    }
  },
  methods: {
    dataTime(value, ft) {
      return parseTime(value, ft);
    },
    dataListFn: function (index) {
      getArticle("industry", this.limit, index).then(res => {
        let limit = this.limit;
        let total = res.data.total;
        this.industryList = res.data.rows;
        this.all = total % limit === 0 ? (total / limit) : (Math.floor(total / limit) + 1);
      });
    },
    btnClick: function (data) {
      if (data !== this.cur) {
        this.cur = data
      }
      this.dataListFn(this.cur.toString());
    },
    pageClick: function () {
      this.dataListFn(this.cur.toString());
    }
  },
}
</script>

<style scoped>

</style>
