<template>
    <div class="index">
        <div id="container"></div>
    </div>
</template>


<script>

    let map, marker, polygon, drawingManager, lngLat,ap;
    export default {
        name: "QQMap",
        data() {
            return {
                position:[
                    {
                        longitude: 0,//经度
                        latitude:0,//纬度
                        city:''
                    }
                ]
            };
        },
        methods: {
            setMapData(){
                // 钢友24.927114,102.849696
                //24.929586,102.853357 一号园区
                let position={
                    latitude:24.929029,
                    longitude:102.852502,
                    city:'昆明市'
                }
                this.position=position;
                var myLatlng = new qq.maps.LatLng(this.position.latitude, this.position.longitude);
                let myOptions = {
                    zoom: 18,
                    center: myLatlng,
                    mapTypeId: qq.maps.MapTypeId.ROADMAP
                }
                let dom=document.getElementById("container");
                map = new qq.maps.Map(document.getElementById("container"), myOptions);
                marker = new qq.maps.Marker({
                    position: myLatlng,
                    map: map,
                    draggable: false //允许鼠标拖动
                });



                //添加窗体
                var infoWin = new qq.maps.InfoWindow({
                    map: map
                });
                infoWin.open();
                //tips  自定义内容
                infoWin.setContent('<div style="width:200px;">'+
                    '<img style="float:left;width:30px;height:30px;"  src="https://yngy.oss-cn-beijing.aliyuncs.com/company/bitbug_favicon.ico"/> '+
                    '<p style="font-weight: bold;font-size: 12px;position: relative;left: -20px;">云南钢友工贸有限公司</p>'+
                    '<p style="font-weight: bold;font-size: 12px;position: relative;left: -20px;"><a href="tel:0871-67415085">0871-67415085</a></p>'+
                    '</div>');
                infoWin.setPosition(myLatlng);

                //添加事件
                qq.maps.event.addListener(marker, 'click', function(event) {
                    infoWin.open();
                })

            },



        },
        mounted() {
            this.setMapData();
        }

    }
</script>

<style>
    #container{
        width: 100%;
        height: 300px;
        /*position: absolute;*/
    }
    @media screen and (max-width: 768px) {
    .contact-map .con .map .right {
            width: 100%;
            height: 200px;
        }
    }

</style>


<style scoped>

</style>
