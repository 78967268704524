<template>
  <div v-if="path!='/vr'"  id="myhead" ref="myhead" class="active">
    <div class="con clearfix">
      <div class="logo">
        <a href="/">
          <img src="../../assets/img/logo_color.png" alt="" class="regular">
          <img src="../../assets/img/logo_color.png" alt="" class="color">
        </a>
      </div>
      <nav :class="showMenu.isShow==true?'customShowMenu':''">
        <ul class="clearfix">
          <li :class="path==='/'?'active':''">
            <h2><a :href="'/'">首页</a></h2>
          </li>
          <li :class="path==='/about'||path==='/honor'?'active':''||path==='/team'?'active':''||path==='/album'?'active':''||path==='/introduce'?'active':''">
            <h2><a :href="'/introduce'">企业概况</a></h2>
          </li>
          <li :class="(path==='/product'?'active':''||path==='/photovoltaic'||path==='/steelstructure'||path==='/weldedpipe')?'active':''">
            <h2><a :href="'/product'">产品中心</a></h2>
          </li>
          <li :class="path==='/produce'?'active':''">
            <h2><a :href="'/produce'">加工生产</a></h2>
          </li>
          <li :class="path==='/logisticsintroduce'?'active':''">
            <h2><a :href="'/logisticsintroduce'">钢友物流</a></h2>
          </li>
          <li :class="path==='/vr'?'active':''">
            <h2><a @click="jump">全景钢友</a></h2>
          </li>
          <li :class="path==='/business'?'active':''">
            <h2><a :href="'/business'">商管中心</a></h2>
          </li>
          <li :class="(path==='/news'||path==='/notice'||path==='/video'||path==='/industry')?'active':''">
            <h2><a :href="'/news'">新闻中心</a></h2>
          </li>
          <li :class="path==='/dynamic'?'active':''">
            <h2><a :href="'/dynamic'">党建之窗</a></h2>
          </li>
          <li :class="path==='/contact'?'active':''">
            <h2><a :href="'/contact'">联系我们</a></h2>
          </li>
        </ul>
      </nav>
      <div class="right clearfix">
        <div class="mobile-btn clearfix">
          <div class="nav-button" @click="showMenuClick">
            <div>
              <span class="top-hr"></span>
              <span class="center-hr"></span>
              <span class="bottom-hr"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewHeader",
  data() {
    return {
      path: this.$route.path,
      showMenu:{
        isShow:false,
        isOpenStatus:0
      }
    }
  },
  methods:{
    jump(){
      window.open('/vr')
    },
    showMenuClick(){
      let that=this;
      let result=this.showMenu.isShow;
      this.showMenu.isShow=!result;
      //console.log("这里的数据："+result)
      if(result==false){
        let status=this.showMenu.isOpenStatus;
        //  debugger;
        if(status==0) {
          this.showMenu.isOpenStatus=1;
          // console.log("这里创建了")
          setTimeout(() => {
            that.$set(that.showMenu, "isShow", false);
            that.$set(that.showMenu, "isOpenStatus", 0);
            that.$forceUpdate();
          }, 5000);
        }
      }
    },
  },
}
</script>
<style scoped>
/*自定义显示缩小菜单*/
.customShowMenu{
  display: block;
  background-color: #ffffff;
  z-index: 99;
}
@media screen and (max-width: 1024px) {
  .con nav > ul > li > h2 a {
    text-align: left;
    width: 100%;
  }
}
</style>
