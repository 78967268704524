import Vue from 'vue'

import Router from 'vue-router'

Vue.use(Router)
//解决重复路由的红色
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//引入各个部分的组件
import HelloWorld from '@/components/HelloWorld'
import Home from '@/components/home/home'
import News from '@/components/news/news'
import Culture from "@/components/culture/Culture"
import Details from "@/components/news/details";
import Test from "@/components/Test";
import Personnel from "@/components/personnel/Personnel";
import group from "@/components/party/group";
import About from "@/components/about/about";
import Leader from "@/components/about/leader";
import History from "@/components/about/history";
import Honor from "@/components/about/honor";
import productCenter from "@/components/product/productCenter";
import Contact from "@/components/about/contact";
import Industry from "@/components/news/industry";
import Notice from "@/components/news/notice";
import dynamic from "@/components/party/dynamic";
import video from "@/components/news/video";
import Introduce from '@/components/about/introduce';
import Team from '@/components/about/team';
import Album from '@/components/about/album.vue';
import Vr from "@/components/vr/Vr";
import PhotovoltaicBracket from "@/components/product/PhotovoltaicBracket";
import SteelStructure from "@/components/product/SteelStructure";
import WeldedPipe from "@/components/product/WeldedPipe";
import LogisticsIntroduce from "@/components/logistics/LogisticsIntroduce";
import Produce from "@/components/processing/produce";
import Business from "@/components/business/buinessmgr";

export default new Router({
    routes: [
        {path: '/HelloWorld', name: 'HelloWorld', component: HelloWorld},
        {path: '/', name: 'Home', component: Home, meta: {title: "首页"}},
        {path: '/news', name: 'News', component: News, meta: {title: "新闻中心"}},
        {path: '/contact', name: 'Contact', component: Contact, meta: {title: "联系我们"}},
        {path: '/culture', name: 'Culture', component: Culture},
        {path: '/details', name: 'Details', component: Details},
        {path: '/personnel', name: 'Personnel', component: Personnel},
        {path: '/test', name: 'Test', component: Test},
        {path: '/logisticsintroduce', name: 'LogisticsIntroduce', component: LogisticsIntroduce, meta: {title: "钢友物流"}},
        {path: '/about', name: 'About', component: About, meta: {title: "企业概况"}},
        {path: '/produce', name: 'Produce', component: Produce, meta: {title: "加工生产"}},
        {path: '/business', name: 'Business', component: Business, meta: {title: "商管中心"}},
        {path: '/introduce', name: 'Introduce', component: Introduce, meta: {title: "公司介绍"}},
        {path: '/team', name: 'Team', component: Team, meta: {title: "团队风采"}},
        {path: '/album', name: 'Album', component: Album, meta: {title: "钢友画册"}},
        {path: '/vr', name: 'Vr', component: Vr, meta: {title: "全景钢友"}},
        {path: '/photovoltaic', name: 'PhotovoltaicBracket', component: PhotovoltaicBracket, meta: {title: "光伏支架解决方案"}},
        {path: '/steelstructure', name: 'SteelStructure', component: SteelStructure, meta: {title: "钢结构解决方案"}},
        {path: '/weldedpipe', name: 'WeldedPipe', component: WeldedPipe, meta: {title: "钢管生产解决方案"}},
        {path: '/leader', name: 'Leader', component: Leader},
        {path: '/history', name: 'History', component: History},
        {path: '/honor', name: 'Honor', component: Honor,meta:{title:"企业荣誉"}},
        {path: '/industry', name: 'Industry', component: Industry,meta:{title:"行业新闻"}},
        {path: '/notice', name: 'Notice', component: Notice,meta:{title:"通知公告"}},
        {path: '/video', name: 'video', component: video,meta:{title:"媒体中心"}},
        {path: '/product', name: 'productCenter', component: productCenter, meta: {title: "产品中心"}},
        {path: '/group', name: 'group', component: group},
        {path: '/dynamic', name: 'dynamic', component: dynamic,meta:{title:"党建动态"}},
        {path: '*', redirect: '/'}
    ],
    mode: "history",
    scrollBehavior: () => ({y: 0})
})
