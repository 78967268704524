<template>
    <div>
    <banner choose-int="1506061397380653058"></banner>
    <bread jup-path="/culture" jupm-page="企业文化"></bread>
     <div class="custombody">文化内容</div>
    <customFooter></customFooter>
    </div>
</template>

<script>
    import customFooter from "@/components/footer/footer";
    import Bread from "@/components/bread/Bread";
    import Banner from "@/components/banner/Banner";
    export default {
        name: "Culture",
        components: {
            customFooter,
            Bread,
            Banner
        }
    }
</script>

<style scoped>
.custombody{
    background-color: #f5f5f5;
    padding: 3.5rem 0 5rem;

}
</style>
