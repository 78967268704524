<template>
  <div class="con-bottom">
    <div class="con w1440">
      <div class="con-link clearfix">
        <h2>友情链接：</h2>
        <ul class="clearfix" v-for="(item,index) in link" :key="index">
          <li><a :href="item.url" target="_blank">{{ item.linkName }}</a></li>
        </ul>
      </div>
      <div class="clearfix">
        <div class="left copy clearfix">
          <p v-if="siteInfo.name" class="en">Copyright @ <span class="zh">{{ siteInfo.name }}</span> All Rights Reserved.</p>
          <p v-if="siteInfo.icp1"><span><img src="../../assets/img/gwab.png" /></span> <span></span>
            <a :href="'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=53019102000383'" target="_blank">{{ siteInfo.icp1}}</a>
          </p>
          <p v-if="siteInfo.icp"><a :href="'http://beian.miit.gov.cn'" target="_blank">{{ siteInfo.icp }}</a> </p>
          <p v-if="siteInfo.serviceEmail">{{ siteInfo.serviceEmail }}</p>
          <p v-if="siteInfo.serviceHot">{{ siteInfo.serviceHot }}</p>
          <p v-if="siteInfo.salesHot">{{ siteInfo.salesHot }}</p>
          <p v-if="siteInfo.complaint">{{ siteInfo.complaint }}</p>
          <p v-if="siteInfo.address">{{ siteInfo.address }}</p>
        </div>
        <div class="right qr clearfix">
          <div class="img">
            <img v-if="siteInfo.mpQr" :src="siteInfo.mpQr" alt="云南钢友订阅号">
            <p>云南钢友订阅号</p>
          </div>
          <div class="img">
            <img v-if="siteInfo.mpServiceQr" :src="siteInfo.mpServiceQr" alt="云南钢友服务号">
            <p>云南钢友服务号</p>
          </div>
          <div class="img">
            <img v-if="siteInfo.wxVideoQr" :src="siteInfo.wxVideoQr" alt="云南钢友视频号">
            <p>云南钢友视频号</p>
          </div>
          <div class="img1">
            <img v-if="siteInfo.wxContact" :src="siteInfo.wxContact" alt="钢小友客服">
            <p>钢小友客服</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getConfigKey, getLink} from "@/api/config";
import SiteConfig from "@/components/footer/siteConfig.json";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Bottom",
  data() {
    return {
      siteInfo: {
        icp: null,
        icp1: '滇公网安备：53019102000383号',
        name: '云南钢友工贸有限公司',
        email: 'email@aryee.cn',
        address: '云南省昆明市呈贡区',
        tel: '0871-00000000',
        serviceEmail: 'kf@aryee.cn',
        serviceHot: null,
        salesHot:'销售热线：0871-67416600 0871-63910580 0871-67415522 0871-67415085',
        complaint: '',
        // complaint: '销售服务投诉：0871-67415559，物流服务投诉：0871-67415087',
        mpQr: require('@/assets/img/mp.jpg'),
        mpServiceQr: require('@/assets/img/mpService.jpg'),
        wxVideoQr: require('@/assets/img/wxVideo.jpg'),
        wxContact: require('@/assets/img/gangxiaoyou.png'),
      },
      link: []
    }
  },
  created() {
    // this.siteInfo = SiteConfig;
    getConfigKey('site.config').then(res => {
      // this.siteInfo = eval('(' + res.data.msg + ')');
      let info = JSON.parse(res.data.msg);
      if (info.icp) this.siteInfo.icp = info.icp;
      if (info.icp1) this.siteInfo.icp1 = info.icp1;
      if (info.name) this.siteInfo.name = info.name;
      if (info.email) this.siteInfo.email = info.email;
      if (info.address) this.siteInfo.address = info.address;
      if (info.tel) this.siteInfo.tel = info.tel;
      if (info.serviceEmail) this.siteInfo.serviceEmail = info.serviceEmail;
      if (info.serviceHot) this.siteInfo.serviceHot = info.serviceHot;
      if (info.complaint) this.siteInfo.complaint = info.complaint;
      if (info.mpQr) this.siteInfo.mpQr = info.mpQr;
      if (info.mpServiceQr) this.siteInfo.mpServiceQr = info.mpServiceQr;
      if (info.wxVideoQr) this.siteInfo.wxVideoQr = info.wxVideoQr;
    });
    getLink("link").then(res => {
      this.link = res.data.rows;
    })
  },
  methods: {}
}
</script>

<style scoped>
.con-bottom {
  /*border-top: 8px solid #ee0000;*/
  border-top: 1px solid rgba(51, 51, 51, 0.1);
  background: #ffffff;
  padding: 2rem 0 1.8rem;
}
.con-bottom .con .con-link {
  padding-bottom: 1.5rem;
  border-bottom: 3px solid rgba(220, 24, 24, 0.9);
}

.con-bottom .con .con-link h2 {
  float: left;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-right: 2rem;
}
.con-bottom .con .con-link ul {
  overflow: hidden;
}

.con-bottom .con .con-link ul li {
  float: left;
  margin-right: 2rem;
}

.con-bottom .con .con-link ul li a {
  font-size: 15px;
  color: #777;
  position: relative;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

.con-bottom .con .con-link ul li a:after {
  content: '\e718';
  font-family: iconfont;
  line-height: 1.5;
  position: absolute;
  top: 0.2rem;
  right: -1rem;
  font-size: 0.6rem;
  /*color: #999;*/
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

.con-bottom .con .con-link ul li a:hover {
  color: #d8222a;
}

.con-bottom .con .con-link ul li a:hover:after {
  color: #d8222a;
  right: -1.2rem;
}

.con-bottom .con .copy {
  color: #666;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  /*font-size: 14px;*/
  padding: 0.8rem 0;
  margin-right: 1rem;
}

.con-bottom .con .copy .en {
  font-size: 16px;
}

.con-bottom .con .copy .zh {
  font-size: 14px;
}

.con-bottom .con .copy a {
  color: #666;
}

.con-bottom .con .copy a:hover {
  color: #ee0000;
}

.con-bottom .con .qr {
  /*padding: 0.8rem 1.2rem;*/
}

.con-bottom .con .qr .img {
  float: left;
  padding: 0 0.3rem;
  /*background-color: #ffffff;*/
  /*margin-left: 5px;*/
  /*width: 150px;*/
}
.con-bottom .con .qr .img1 {
  float: left;
  padding: 0 0.3rem;
  /*background-color: #ffffff;*/
  /*margin-left: 5px;*/
  /*width: 150px;*/
}
.con-bottom .con .qr .img1 p {
  font-size: 0.9rem;
  text-align: center;
}
.con-bottom .con .qr .img1 img {
  /*-webkit-filter: opacity(50%);*/
  /*filter: opacity(50%);*/
  max-height: 125px;
  margin-top: 5px;
}
.con-bottom .con .qr .img p {
  font-size: 0.9rem;
  text-align: center;
}
.con-bottom .con .qr .img img {
  /*-webkit-filter: opacity(50%);*/
  /*filter: opacity(50%);*/
  max-height: 130px;
}
.con-bottom .con .left {
  float: left;
  /*width: 60%;*/
  /*padding-right: 3rem;*/
}
.con-bottom .con .right {
  float: left;
  /*width: 40%;*/
  padding-top: 0.3rem;
}
</style>
