<template>
    <div>
        <banner choose-int="5"></banner>
        <bread jup-path="/history" jup-page="发展历程"></bread>
        <div class="history scroll-num">
            <div class="con w1440 clearfix">
                <div class="left">
                    <h2 class="wow fadeInUp slow animated" style="visibility: visible; animation-name: fadeInUp;">发展历程</h2>
                    <div class="text wow fadeInUp slow animated" style="visibility: visible; animation-name: fadeInUp;">
                        <p>云南钢友 ( 简称“钢友集团”)始建于1958年，经过60多年的积淀，目前已发展成为我国华南、西南地区乃至泛北部湾经济圈特大型的钢铁联合企业，跻身2021全球钢企50强第20位、中国企业500强第191位。</p>
                        <p>如今的钢友集团，如一艘钢铁巨轮，紧跟钢铁工业发展潮流。</p>
                    </div>
                </div>
                <div class="right">
                    <div class="data-list">
                        <ul class="clearfix">
                            <li>
                                <div class="tu">
                                    <img src="img/icon_03.png" alt="">
                                </div>
                                <div class="num">
                                    <h2 class="n" data-target="50">20</h2>
                                    <span>大</span>
                                </div>
                                <p>跻身全球50大钢企第20位</p>
                            </li>
                            <li>
                                <div class="tu">
                                    <img src="img/icon_02.png" alt="">
                                </div>
                                <div class="num">
                                    <h2 class="n" data-target="500">191</h2>
                                    <span>位</span>
                                </div>
                                <p>跻身中国500强企业第191位</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <customFooter></customFooter>
    </div>
</template>

<script>
    import customFooter from "@/components/footer/footer";
    import Bread from "@/components/bread/Bread";
    import Banner from "@/components/banner/Banner";

    export default {
        name: "History",
        components: {
            customFooter,
            Bread,
            Banner
        }
    }
</script>

<style scoped>

</style>
