<template>
    <div class="block">
        <el-carousel :height="bannerHeight+'px'">
            <el-carousel-item v-for="item in banner" :key="item.index">
                <h3 class="small">
                    <img ref="bannerHeight" @load="imgLoad" style="width: 100%" :src="item.savePath" :alt="item.imgName"
                         @click="jumpMap(item)"/>
                </h3>
            </el-carousel-item>
        </el-carousel>
    </div>

</template>

<script>
    import {getImages} from "@/api/images";

    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Carousel",
        data() {
            return {
                banner: [],
                bannerHeight: "",
            }
        },
        created() {
            getImages("carousel").then(res => {
                this.banner = res.data.rows;
            });
        },
        methods: {
            jumpMap(obj) {
                let id = obj.imgId;
                if ('1646429098983923714' == id) {
                    window.open("/vr");
                }
            },
            imgLoad() {
                this.$nextTick(() => {
                    let ref = this.$refs
                    this.bannerHeight = ref.bannerHeight && ref.bannerHeight.length ? ref.bannerHeight[0].height : '';
                })
            }
        }

    }
</script>

<style scoped>
    el-carousel__container {
        height: 550px;
    }

    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 550px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }
</style>
