import request from '@/utils/request'

export function getImages(type,size,num) {
    if (size === undefined || size === '') size = 100;
    if (num === undefined || num === '') num = 1;
    return request({
        url: '/siteweb/images/list/' + type + "?pageSize=" + size + "&pageNum=" + num,
        method: 'get'
    })
}

export function getImagesById(id) {
    return request({
        url: '/siteweb/images/' + id,
        method: 'get'
    })
}
