<template>
  <div class="server">
    <div class="con w1440">
      <div class="title clearfix">
        <div class="left clearfix">
          <div class="tu">
            <img src="../../assets/img/product-index.png" alt="">
          </div>
          <div class="text">
            <p>专业的团队保障、专心为顾客创利、专注顾客的需求、为顾客服务，钢友、您信任的伙伴、您忠诚的朋友</p>
          </div>
        </div>
        <div class="right">
          <h3>更多产品</h3>
          <a :href="'product'">
            <span class="iconfont"></span>
          </a>
        </div>
      </div>
      <div class="swiper-container swiper-container-initialized swiper-container-horizontal">
        <div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px); transition-duration: 10ms;">
          <div v-for="item in productList" :key="item.index" class="swiper-slide" style="max-width: 252px; margin-right: 26px;">
            <a :href="item.url" target="_blank">
              <div class="tu">
                <img :src="item.savePath" :alt="item.imgName">
              </div>
              <div class="text">
                <h2>{{item.imgName}}</h2>
              </div>
            </a>
          </div>
        </div>
<!--        <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">-->
<!--          <span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 1">-->
<!--          </span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 4"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 5"></span></div>-->
        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import {getImages} from "@/api/images";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Product",
  data(){
    return {
      productList:[]
    }
  },
  created() {

  },
  mounted() {
    getImages("home-product",8).then(res => {
      this.productList = res.data.rows;
    });
    new Swiper('.swiper-container', {
      loop: true,
      slidesPerView:4,
      autoplay:true,
      speed:2000,
      // 如果需要分页器
      pagination: '.swiper-pagination',
      // 如果需要前进后退按钮
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
      //如果需要自动切换海报
      autoplay: {
        delay: 1000,//时间 毫秒
        disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
      },
    })
  }
}
</script>

<style scoped>

</style>
