<template>
  <div>
    <banner choose-int="1506062505469632514"></banner>
    <bread :jup-path="'/'+pagePath" :jup-page="pageTitle"></bread>
    <div class="news-details">
      <div class="con w1440">
        <div class="news-center">
          <div class="title">
            <h1>{{ info.title }}</h1>
            <div class="brief">
              <h3>
                <span class="iconfont"></span>发布日期：<span>{{ dataTime(info.createTime, "{y}-{m}-{d}") }}</span>
                <span :class="'text'" v-if="info.originalAuthor===null" style="margin-left: 1rem;">{{
                    info.author
                  }}</span>
                <span v-else style="margin-left: 1rem;">来源：<a :href="info.originalUrl"
                                                              :target="info.originalUrl===null?'':'_blank'">{{
                    info.originalAuthor
                  }}</a></span>
              </h3>
            </div>
          </div>
          <div class="text" v-html="info.content"></div>
        </div>
        <div class="news-bottom clearfix">
          <div class="left">
            <a @click="preClick" class="clearfix">
              <div class="prev">
                <span class="iconfont"></span>上一页
              </div>
              <h2>{{ pre.title || "没有了" }}</h2>
            </a>
          </div>
          <div class="middle">
            <a><span class="iconfont"></span></a>
          </div>
          <div class="right">
            <a @click="nextClick" class="clearfix">
              <div class="next">下一页<span class="iconfont"></span></div>
              <h2>{{ next.title || "没有了" }}</h2>
            </a>
          </div>
        </div>
        <div class="news-share">
          <div class="clearfix share">
            <a href="javascript:;" onmouseover="this.className = 'weixin on';" onmouseout="this.className = 'weixin';" class="weixin" data-cmd="weixin" title="官方订阅号">
              <div class="weixin_nr">
                <div class="arrow"></div>
                <img src="../../assets/img/mp.jpg" width="150" height="150"/>
                关注官方订阅号
              </div>
            </a>
            <a href="javascript:;" onmouseover="this.className = 'weixin on';" onmouseout="this.className = 'weixin';" class="weixin" data-cmd="weixin" title="官方服务号">
              <div class="weixin_nr">
                <div class="arrow"></div>
                <img src="../../assets/img/mpService.jpg" width="150" height="150"/>
                关注官方服务号
              </div>
            </a>
            <a href="tel:0871-67415558" class="bds_qzone" data-cmd="qzone" title="QQ"></a>
          </div>
        </div>
      </div>
    </div>
    <service></service>
    <bottom></bottom>
  </div>
</template>

<script>
import Bread from "@/components/bread/Bread";
import Banner from "@/components/banner/Banner";
import {getArticleById} from "@/api/article";
import {parseTime} from "@/utils/aryee";
import Bottom from "@/components/footer/Bottom";
import Cookies from 'js-cookie';
import Service from "@/components/service/service";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Details",
  inject: ['reload'],
  components: {
    Service,
    Bottom,
    Bread,
    Banner
  },
  data() {
    return {
      info: {},
      id: this.$route.query.articleId,
      pagePath: undefined,
      pageTitle: undefined,
      pre: {articleId: undefined, title: undefined},
      next: {articleId: undefined, title: undefined}
    };
  },
  created() {
    let pathKey = "pagePath";
    let titleKey = "pageTitle";
    let path = this.$route.params.path;
    let title = this.$route.params.title;
    if (path !== undefined && title !== undefined) {
      Cookies.set(pathKey, path);
      Cookies.set(titleKey, title);
      this.pagePath = path;
      this.pageTitle = title;
    }
    if (this.pagePath === undefined) {
      this.pagePath = Cookies.get(pathKey);
      this.pageTitle = Cookies.get(titleKey);
    }
    if (this.pagePath === undefined) {
      this.pagePath = '';
      this.pageTitle = '首页';
    }
    console.log(this.$route.params.path);
    getArticleById(this.id).then(res => {
      this.info = res.data.data;
      if (this.info.title !== undefined) {
        document.title = this.info.title + " - 云南钢友";
        document.querySelector('meta[name="keywords"]').setAttribute('content', this.info.keywords);
        document.querySelector('meta[name="description"]').setAttribute('content', this.info.description);
      }
      if (res.data.pre !== undefined) {
        this.pre = res.data.pre;
      }
      if (res.data.next !== undefined) {
        this.next = res.data.next;
      }
    });
  },
  methods: {
    dataTime(value, ft) {
      return parseTime(value, ft);
    },
    preClick() {
      if (this.pre.articleId !== undefined) {
        this.reload();
        this.$router.push({
          path: '/details',
          params: {path: this.pagePath, title: this.pageTitle},
          query: {articleId: this.pre.articleId}
        });
      }
    },
    nextClick() {
      if (this.next.articleId !== undefined) {
        this.reload();
        this.$router.push({
          path: '/details',
          params: {path: this.pagePath, title: this.pageTitle},
          query: {articleId: this.next.articleId}
        });
      }
    }
  }
}
</script>

<style scoped>
.weixin .weixin_nr {
  width: 170px;
  height: 200px;
  padding: 10px;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  left: -180px;
  top: -100px;
  display: none;
  border: 1px solid #f0f0f0;
}

.weixin .weixin_nr img {
  margin-bottom: 5px;
}

.weixin .weixin_nr .arrow {
  width: 0;
  height: 0;
  border-top: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  left: 50px;
  top: 140px;
}

.weixin.on .weixin_nr {
  display: block;
}

@media screen and (max-width: 768px) {
  .weixin .weixin_nr{
    position: absolute;
    left: -60px;
    top: -210px;
  }
}


</style>

<style>
  .ql-align-center {
    text-align: center!important;

  }

</style>
