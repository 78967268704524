<template>
  <div id="app">
    <!--    <Myheader></Myheader>-->
    <new-header></new-header>
    <!-- 展示router -->
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Myheader from './components/header/header.vue'
import NewHeader from "@/components/header/NewHeader";


export default {
  name: 'App',
  components: {
    // HelloWorld,
    // Myheader,
    //Carousel,
    //customFooter,
    NewHeader
  },
  provide() {
    return {reload: this.reload}
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
</style>
