<template>
  <div>
    <banner choose-int="1506075601609252866"></banner>
    <bread jup-path="/about" jup-page="关于我们"></bread>
    <div class="brief scroll-num">
      <div class="con w1440 clearfix">
        <div class="left wow fadeInUp slow animated" style="visibility: visible; animation-name: fadeInUp;">
<!--          <h2>{{ aboutUs.title }}</h2>-->
          <div class="text">
            <span v-html="aboutUs.content"></span>
          </div>
        </div>
        <div class="right wow fadeInDown slow animated" style="visibility: visible; animation-name: fadeInDown;">
          <img :src="aboutUs.coverImage" :alt="aboutUs.title">
        </div>
      </div>
    </div>
    <customFooter></customFooter>
  </div>
</template>

<script>
import customFooter from "@/components/footer/footer";
import Bread from "@/components/bread/Bread";
import Banner from "@/components/banner/Banner";
import {getArticleById} from "@/api/article";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "About",
  components: {
    customFooter,
    Bread,
    Banner
  },
  data() {
    return {
      aboutUs: {}
    };
  },
  created() {
    getArticleById("1505332509880967170").then(res=>{
      this.aboutUs = res.data.data;
    });
  }
}
</script>

<style scoped>

</style>
