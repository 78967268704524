import Vue from 'vue'
import App from './App.vue'
//引入公共样式
import './assets/css/base.css'
import './assets/css/iconfont.css'
import './assets/css/animation.css'
import './assets/css/swiper.min.css'
import './assets/css/common.css'
import './assets/css/index.css'
import './assets/css/news_details.css'
import './assets/css/news_media.css'
import './assets/css/people_index.css'
import './assets/css/contact_index.css'
import './assets/css/party_index.css'
import './assets/css/about_index.css'
import './assets/css/about_chairman.css'
import './assets/css/about_honor.css'
import './assets/css/about_history.css'
import './assets/css/culture_system.css'
import './assets/css/product_index.css'
import './assets/css/page.css'
import 'element-ui/lib/theme-chalk/index.css';
import Element from 'element-ui';
import router from './router';
import store from './store/index';
import './assets/js/swiper.min';

Vue.config.productionTip = false
Vue.use(Element)

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title + " - 云南钢友";
        document.querySelector('meta[name="keywords"]').setAttribute('content', "钢友,钢友工贸,云南钢友,钢材市场快讯");
        document.querySelector('meta[name="description"]').setAttribute('content', "云南钢友工贸有限公司是云南钢铁智能供应链服务企业,公司在云南区域市场、钢铁产业供应链中长期从事各品种规格钢卷(板)、型材和建筑用钢材的购进、加工和配送销售业务，是高效集成:商流、信息流、资金流、货物流并协同运行的供应链服 务型企业，公司多年来以“诚信为本”，奉行“优良信誉、优质服务”的营销理念，通过为广大客户提供优质便利的智能型加工配送销售服务，赢得了市场众多终端客户和商家的信任。");
    }
    next();
});
new Vue({
    router, //引入路由文件
    store,
    render: h => h(App),
}).$mount('#app');



