<template>
  <div>
    <service></service>
    <bottom></bottom>
  </div>
</template>

<script>
import Bottom from "@/components/footer/Bottom";
import Service from "@/components/service/service";

export default {
  name: "customFooter",
  components: {
    Service,
    Bottom,
  }
}
</script>

<style scoped>
.con-link {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.con-link h2 {
  float: left;
  font-size: 0.8rem;
  color: #333;
  font-weight: bold;
  margin-right: 2rem;
}

.con-link ul {
  overflow: hidden;
}

.con-link ul li {
  float: left;
  margin-right: 5rem;
}

.con-link ul li a {
  font-size: 0.8rem;
  color: #777;
  position: relative;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

.con-link ul li a:after {
  content: '\e718';
  font-family: iconfont;
  line-height: 0.8;
  position: absolute;
  top: 0.2rem;
  right: -1rem;
  font-size: 0.6rem;
  color: #999;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

.con-link ul li a:hover {
  color: #d8222a;
}

.con-link ul li a:hover:after {
  color: #d8222a;
  right: -1.2rem;
}

</style>
