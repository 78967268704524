<template>
    <div>
        <banner choose-int="1506075601609252866"></banner>
        <bread jup-path="/album" jup-page="钢友画册"></bread>
        <div class="brief scroll-num">
            <div class="con w1440 clearfix">
                <div class="wow fadeInUp slow animated" style="visibility: visible; animation-name: fadeInUp;">
                    <!--          <h2>{{ aboutUs.title }}</h2>-->
                    <div class="text">
                        <div id="fix" v-html="aboutUs.content"></div>
                    </div>
                </div>
            </div>
        </div>
        <customFooter></customFooter>
    </div>
</template>

<script>
    import customFooter from "@/components/footer/footer";
    import Bread from "@/components/bread/Bread";
    import Banner from "@/components/banner/Banner";
    import {getArticleById} from "@/api/article";

    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "album",
        components: {
            customFooter,
            Bread,
            Banner
        },
        data() {
            return {
                aboutUs: {}
            };
        },
        created() {
            getArticleById("1782248106898595842").then(res => {
                this.aboutUs = res.data.data;
            });
        }
    }
</script>

<style scoped>
</style>

<style>
    #fix {
        /*border: 1px solid red;*/
    }

    #fix p {
        /*border: 1px solid chartreuse;*/
    }

    #fix p img {
        width: 100%;
        height: auto;
    }
</style>
