import { render, staticRenderFns } from "./produce.vue?vue&type=template&id=a379c680&scoped=true&"
import script from "./produce.vue?vue&type=script&lang=js&"
export * from "./produce.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a379c680",
  null
  
)

export default component.exports