<template>
  <div class="news">
    <div class="con w1440 clearfix">
      <div class="news-con">
        <div class="title clearfix wow slideInLeft slow animated"
             style="visibility: visible; animation-name: slideInLeft;">
          <h2>钢友新闻</h2>
          <h3><a :href="'/news'">更多<span class="iconfont"></span></a></h3>
        </div>
        <div class="news-list">
          <ul class="clearfix">
            <li v-for="item in newsList" :key="item.index" class="wow fadeInUp slow animated" style="visibility: visible; animation-name: fadeInUp;">
              <router-link :to="{name:'Details',params:{path:'news',title:'钢友新闻'},query:{articleId:item.articleId}}">
              <div class="top">
                <img :src="item.coverImage" alt="">
                <div class="mask">
                  <span class="iconfont"></span>
                </div>
              </div>
              <div class="bottom">
                <h2>{{ item.title }}</h2>
                <div class="txt">
                  <h3>
                    <span class="iconfont"></span><span>{{ dataTime(item.createTime) }}</span>
                  </h3>
                </div>
              </div>
              </router-link>
            </li>
          </ul>
          <!--修改结束 -->
        </div>
      </div>
      <div class="report-con">
        <div class="title clearfix wow slideInUp slow animated" style="visibility: visible; animation-name: slideInUp;">
          <h2>最新公告</h2>
          <h3><a :href="'/notice'">更多<span class="iconfont"></span></a></h3>
        </div>
        <div class="news-other">
          <ul>
            <li v-for="item in noticeList" :key="item.index" class="wow slideInUp  slow animated"
                style="visibility: visible; animation-name: slideInUp;">
              <router-link class="clearfix" :to="{name:'Details',params:{path:'notice',title:'通知公告'},query:{articleId:item.articleId}}">
                <div class="time">
                  <div class="txt">
                    <h3>{{ dataTime(item.createTime, "{d}") }}</h3>
                    <h4>{{ dataTime(item.createTime, "{y}-{m}") }}</h4>
                  </div>
                </div>
                <div class="text">
                  <h2>{{ item.title }}</h2>
                  <p></p>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="more" style="height: 2rem;width: 100%"></div>
    <div class="news-bottom w1440">
      <div class="con bg-color clearfix">
        <div class="left wow slideInLeft slow animated" style="visibility: visible; animation-name: slideInLeft;">
          <div class="news-other">
            <h2>市场快讯</h2>
            <ul>
              <li v-for="(item,index) in mpList" :key="index">
                <a :href="item.url" class="clearfix" :target="'_blank'">
                  <p><span style="color: #ee0000">{{index+1}}.</span>{{ item.title }}</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="right clearfix wow slideInRight slow animated"
             style="visibility: visible; animation-name: slideInRight;">
          <div class="news-other">
            <h2>行业资讯</h2>
            <ul>
              <li v-for="item in industry" :key="item.index">
                <router-link class="clearfix" :to="{name:'Details',params:{path:'industry',title:'行业新闻'},query:{articleId:item.articleId}}">
                  <span class="time">{{ dataTime(item.createTime, "{y}.{m}.{d}") }}</span>
                  <p>{{ item.title }}</p>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getArticle,getMp} from "@/api/article";
import {parseTime} from "@/utils/aryee";

export default {
  name: "NewsNode",
  data() {
    return {
      newsList: [],
      noticeList: [],
      industry: [],
      mpList: []
    };
  },
  created() {
    getArticle("company",2).then(res => {
      this.newsList = res.data.rows;
    });
    getArticle("industry",8).then(res => {
      this.industry = res.data.rows;
    });
    getArticle("notice",3).then(res => {
      this.noticeList = res.data.rows;
    });
    getMp().then(res => {
      this.mpList = res.data.data;
    });
  },
  methods: {
    dataTime(value, ft) {
      return parseTime(value, ft);
    }
  }
}
</script>

<style scoped>
.news-bottom {
  padding: 0 2rem;
}
</style>
