import request from '@/utils/request'
export function info(username, password, captcha, uuid) {
    return request({
        url: '/siteweb/login',
        headers: {
            isToken: false
        },
        method: 'post',
        data: { username, password, captcha, uuid }
    })
}
export function getLink(type) {
    return request({
        url: '/siteweb/link/list/' + type,
        method: 'get'
    })
}

export function getConfigKey(configKey) {
    return request({
        url: '/account/config/configKey/' + configKey,
        method: 'get'
    })
}
