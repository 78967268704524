<template>
  <div>
    <banner choose-int="5"></banner>
    <bread jup-path="/leader" jup-page="董事长致辞"></bread>
    <div class="chairman">
      <div class="con w1440">
        <div class="title">
          <h2>董事长致辞</h2>
          <p>建一流集团 做世界强企 <br> 创领先价值 铸百年品牌</p>
        </div>
        <div class="chairman-con clearfix">
          <div class="left">
            <div class="top">
              <img src="" alt="">
            </div>
            <div class="bottom">
              <img src="img/about/chairman/text.png" alt="">
              <p>钢友集团党委书记、董事长</p>
            </div>
          </div>
          <div class="right">
            <h2>钢友，广西钢铁工业的摇篮，如一璀璨的明珠镶嵌在祖国南疆。</h2>
            <div class="sign"><img src="img/about/sign_text.png" alt=""></div>
            <div class="text scroll-1 mCustomScrollbar _mCS_1">
              <div id="mCSB_1" class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" style="max-height: none;"
                   tabindex="0">
                <div id="mCSB_1_container" class="mCSB_container" style="position: relative; top: 0px; left: 0px;"
                     dir="ltr"><p>
                  1958年1月，中共中央政治局南宁会议期间，毛泽东同志听取关于建设柳州工业区的规划汇报后，同意钢友选址方案以及发展规划。同年7月1日，钢友1号高炉破土动工。从此，在柳州市北郊的雀儿山脚下，从祖国四面八方汇聚而来的一万多名建设者头顶青天，脚踏荒原，开疆拓土，建厂兴业，拉开了构建钢铁强企的序幕。</p>
                  <p>
                    60年多来，在党中央、国务院，广西壮族自治区党委、人民政府，以及自治区国资委的亲切关怀下，在社会各界、合作伙伴、广大客户的大力支持下，钢友人艰苦创业、自强不息、追求卓越，以豪迈气魄和智慧，挣脱发展桎梏、冲破钢铁困境，使钢友从当初的一个年产钢能力仅10余万吨的小厂，成长为一个年产钢超千万吨的特大型钢铁联合企业。</p>
                  <p>
                    今日钢友，已跻身中国企业500强、全球钢铁企业50强、中国十大优秀钢企品牌以及钢铁行业竞争力极强A+级企业行列，成为我国华南、西南地区一家生态型钢铁强企，在服务我国工业建设中，走出了一条现代化大型钢铁联合企业的兴企强国之路。</p>
                  <p>从无到有，从小到大，从弱到强，钢友用生生不息的拼搏精神，创造了中国钢铁工业史上的“发展奇迹”，“钢友形象”“钢友速度”“钢友业绩”日新月异，享誉全国。</p>
                  <p>60多年峥嵘岁月，成就辉煌钢友的，绝不仅仅是令人瞩目的企业生产经营业绩，还有时间沉淀下来的精神、文化和信念，履行责任、回报社会的真情和担当。</p>
                  <p>
                    钢友，始终坚持党的领导，自觉地肩负起推动企业改革发展的重任。充分发挥党组织的领导核心和政治核心作用，把方向、管大局、保落实，筑牢企业发展的“根”和“魂”，把政治优势变为企业的竞争优势。&nbsp;</p>
                  <p>钢友，坚持“持续改进、追求卓越”的质量观，以精益求精的工匠精神，为客户铸造精品，超越期望。</p>
                  <p>钢友，重视营销服务，积极“走出去”，实现从“产品制造商”到“技术服务商”的转变，创新服务手段，提升客户体验，形成长期合作互惠共赢的良好格局。</p>
                  <p>钢友，坚持走绿色发展道路。累计投资超100亿元治理“三废”，实现了现代化钢企与宜居城市的和谐相融，也成就了柳州市“工业城市中山水最美，山水城市中工业最强”的美誉。</p>
                  <p>钢友，积极践行和越发展之道，回报社会、造福社会，广泛投身于社会公益事业。在企业改革、转型、发展任务艰巨的背景下，将脱贫攻坚重任扛在肩头，有力推动了定点扶贫村经济社会的发展。</p>
                  <p>
                    钢铁意志，大海情怀。站在新的发展起点上，处于春风浩荡的新时代里，钢友将以党的十九大精神和习近平新时代中国特色社会主义思想为指引，秉承“包容、创新、超越、共享”的企业核心理念，抓住国家实施“一带一路”倡议机遇，借北部湾大开发之势，以“本部转型升级、沿海有序发展、多元聚焦集群、集团战略管控”四大战略转型为重点，全面深化供给侧改革，大手笔实施沿海战略，加快发展向海经济，向着挺进世界500强的目标提速跃进，在广西乃至全国的钢铁生产史上续写光彩华章。</p>
                  <p>向海图强，逐梦深蓝；基业长青，奋斗不息。衷心期待与社会各界、合作伙伴、广大客户精诚合作，携手共进，共同开创更加美好的未来！</p></div>
                <div id="mCSB_1_scrollbar_vertical"
                     class="mCSB_scrollTools mCSB_1_scrollbar mCS-light mCSB_scrollTools_vertical"
                     style="display: block;">
                  <div class="mCSB_draggerContainer">
                    <div id="mCSB_1_dragger_vertical" class="mCSB_dragger"
                         style="position: absolute; min-height: 30px; display: block; height: 267px; max-height: 502px; top: 0px;">
                      <div class="mCSB_dragger_bar" style="line-height: 30px;"></div>
                    </div>
                    <div class="mCSB_draggerRail"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <customFooter></customFooter>
  </div>
</template>

<script>
import customFooter from "@/components/footer/footer";
import Bread from "@/components/bread/Bread";
import Banner from "@/components/banner/Banner";
import {getArticleById} from "@/api/article";

export default {
  name: "Leader",
  components: {
    customFooter,
    Bread,
    Banner
  },
  data() {
    return {
      info: {title: null, content: null, coverImage: null}
    };
  },
  created() {
    getArticleById("1505340236011233281").then(res => {
      this.info = res.data.data;
    });
  }
}
</script>

<style scoped>

</style>
