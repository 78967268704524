<template>
  <div>
    <banner choose-int="1506075601609252866"></banner>
    <bread jup-path="/honor" jup-page="企业荣誉"></bread>
    <div class="honor">
      <div class="con w1440">
        <div class="honor-list">
          <ul class="clearfix search-result-list">
            <li v-for="(item,index) in infoList" :key="index" @click="liClick(index)">
              <div class="top">
                <el-image :src="item.savePath" :preview-src-list="[item.savePath]"></el-image>
                <div class="mask">
                  <div class="alert-btn">
                    <span class="iconfont"></span>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <h3>{{ item.imgName }}</h3>
                <h2>{{ item.description }}</h2>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <customFooter></customFooter>
  </div>
</template>

<script>
import customFooter from "@/components/footer/footer";
import Bread from "@/components/bread/Bread";
import Banner from "@/components/banner/Banner";
import {getImages} from "@/api/images";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Honor",
  components: {
    customFooter,
    Bread,
    Banner
  },
  data() {
    return {
      infoList: [],
      info: {},
      photo: [{src: null}],
      errorImg: null
    };
  },
  created() {
    getImages("honor").then(res => {
      this.infoList = res.data.rows;
    });
  },
  methods: {}
}
</script>

<style scoped>

</style>
