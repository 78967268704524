<template>
  <div>
      后台数据访问
      <el-button @click="mytest">点击我试下</el-button>
  </div>
</template>

<script>
    import http from '@/utils/http'
    export default {
        name: "Test",
        methods:{
            mytest(){
                console.log("你点击了这里...")
                http.get("/api/test").then(res=>{
                        console.log("获取的内容"+res);
                        console.log("获取的内容"+JSON.stringify(res));
                    }).catch(err=>{
                       console.log(err)
                    });
            },
        }
    }
</script>

<style scoped>

</style>
