<template>
    <div class="contact">
        <div class="con w1440 clearfix">
            <div class="left wow slideInDown slow animated" style="visibility: visible; animation-name: slideInDown;">
                <h2 style="font-size: 1.6rem;">云南钢友为您服务</h2>
                <p>诚交天下钢友、信做市场生意</p>
            </div>
            <div class="right">
                <ul class="clearfix">
                    <li class="wow slideInUp slow animated" style="visibility: visible; animation-name: slideInUp;">
                        <a :href="'/contact'">
                            <div class="title">
                                <img src="../../assets/img/service-phone.png" alt="">
                                <h2>联系我们</h2>
                            </div>
                            <p>免费为客户提供支持</p>
                        </a>
                    </li>
                    <li class="wow slideInUp slow animated" style="visibility: visible; animation-name: slideInUp;">
                        <a>
                            <div class="title">
                                <img src="../../assets/img/service-msg.png" alt="">
                                <h2>留言反馈</h2>
                            </div>
                            <p>您的意见帮助我们不断改进</p>
                        </a>
                    </li>
                    <li class="wow slideInUp slow animated" style="visibility: visible; animation-name: slideInUp;">
                        <a>
                            <div class="title">
                                <img src="../../assets/img/service-fw.png" alt="">
                                <h2>服务支持</h2>
                            </div>
                            <p>提供7*24服务支持</p>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
      // eslint-disable-next-line vue/multi-word-component-names
      name: "Service",
    }
</script>

<style scoped>

</style>
