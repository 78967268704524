<template>
    <div>
        <banner choose-int="1506062297889333249"></banner>
        <bread jup-path="/personnel" jupm-page="人力资源"></bread>
<!--        <div class="people" style="background: url(../../assets/img/people/tu.jpg) no-repeat center center;background-size: cover">-->
        <div class="people"  :style="{backgroundImage:'url('+image+')',backgroundSize:'cover',noBeat:'center'}">
            <div class="con w1440 clearfix">
                <div class="left">
                    <h2>人才战略</h2>
                    <p>人力资源是企业第一资源，是企业发展之本，钢友坚持以人为本的方针，注重人才建设，关注员工成长与发展，尊重员工价值，坚持发展为了职工、发展依靠职工、发展成果由职工共享</p>
                </div>
                <div class="right">
                    <ul>
                        <li class="active">
                            <div class="text">
                                <h2>以结果为导向</h2>
                                <p>钢友坚持绩效导向的企业文化，努力为员工提供有竞争力的薪酬待遇，员工的薪酬取决于对公司的价值贡献，鼓励创新与高效</p>
                            </div>
                            <div class="btn"></div>
                        </li>
                        <li>
                            <div class="text">
                                <h2>良好的人才成长机制</h2>
                                <p> 钢友坚持“人人皆是人才，人人皆可成才”的人才理念；通过构建公平的竞争机制和科学的人才评价选拔机制，形成“上岗靠竞争，竞争凭业绩”的良好氛围，促进优秀人才脱颖而出和快速成长；通过不断完善经营管理人才、专业技术人才和技能人才的职业发展通道，形成纵向、横向的人才成长模式，为员工的成长和发展提供广阔的平台。 </p>
                            </div>
                            <div class="btn"></div>
                        </li>
                        <li>
                            <div class="text">
                                <h2>努力打造职业化员工队伍</h2>
                                <p>钢友以打造职业化员工队伍为“十二五”规划重要任务，注重员工培训与开发，为员工提供各种培训与发展机会，坚持员工与企业共同发展。 </p>
                            </div>
                            <div class="btn"></div>
                        </li>
                        <li>
                            <div class="text">
                                <h2>构建新型和谐劳动关系</h2>
                                <p>钢友真诚地关心员工，尊重员工，坚持一切从员工利益出发，员工工资收入与企业效益同步增长；坚持为员工提供稳定的就业机会，持续改善员工工作环境，在金融危机和欧债危机中坚持不裁员；引导员工参与决策管理，强调以事业留人、机制留人、待遇留人和感情留人，努力构建积极向上、团结和谐的劳动关系和工作环境。  钢友，为员工提供奋斗的平台，成长的环境，成功的机会；钢友，有能力者的舞台，只要有付出就会有收获。</p>
                            </div>
                            <div class="btn"></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <customFooter></customFooter>
    </div>
</template>

<script>
    import customFooter from "@/components/footer/footer";
    import Bread from "@/components/bread/Bread";
    import Banner from "@/components/banner/Banner";
    export default {
        name: "Personnel",
        components: {
            customFooter,
            Bread,
            Banner
        },
        data(){
            return{
                image:require('../../assets/img/people/tu.jpg')

            }
        }
    }
</script>

<style scoped>

</style>
