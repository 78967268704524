<template>
  <div class="banner-inner" v-if="banner.savePath!==null" :height="bannerHeight+'px'">
    <img ref="bannerHeight" @load="imgLoad" style="width: 100%" :src="banner.savePath" alt="">
<!--    <div class="text">-->
<!--      <div class="con w1440">-->
<!--        <div class="tu wow fadeInDown slow animated" style="visibility: visible; animation-name: fadeInDown;">-->
<!--          <img :src="banner.thumbnail" alt="">-->
<!--        </div>-->
<!--        <p class="wow fadeInUp slow animated" style="visibility: visible; animation-name: fadeInUp;">{{banner.imgName }}<br>{{ banner.description }}-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import {getImagesById} from "@/api/images";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Banner",
  props: {
    chooseInt: {
      type: String
    },
  },
  data() {
    return {
      banner: {
        thumbnail: null,
        savePath: null,
        imgName: null,
        description: null
      },
      bannerHeight: ''
    }
  },
  created() {
    getImagesById(this.chooseInt).then(res => {
      this.banner = res.data.data;
    });
  },
  mounted() {
    this.imgLoad();
    window.addEventListener('resize', () => {
      this.imgLoad();
    }, false)
  },
  methods: {
    imgLoad() {
      this.$nextTick(() => {
        let ref = this.$refs
        this.bannerHeight = ref.bannerHeight && ref.bannerHeight.length ? ref.bannerHeight[0].height : '';
      })
    }
  }
}
</script>

<style scoped>

</style>
