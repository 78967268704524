import { render, staticRenderFns } from "./introduce.vue?vue&type=template&id=ae3054c2&scoped=true&"
import script from "./introduce.vue?vue&type=script&lang=js&"
export * from "./introduce.vue?vue&type=script&lang=js&"
import style1 from "./introduce.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae3054c2",
  null
  
)

export default component.exports