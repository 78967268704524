<template>
    <div>
        <banner choose-int="1704738531471892481"></banner>
        <bread jup-path="/business" jup-page="商管中心"></bread>
        <div class="brief scroll-num">
            <div class="con w1440 clearfix">
                <div class="wow fadeInUp slow animated" style="visibility: visible; animation-name: fadeInUp;">
                    <!--          <h2>{{ aboutUs.title }}</h2>-->
                    <div class="text">
                        <div id="fix" v-html="aboutUs.content"></div>
                    </div>
                </div>
            </div>
        </div>
        <customFooter></customFooter>
    </div>
</template>

<script>
import customFooter from "@/components/footer/footer";
import Bread from "@/components/bread/Bread";
import Banner from "@/components/banner/Banner";
import {getArticleById} from "@/api/article";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Business",
  components: {
    customFooter,
    Bread,
    Banner
  },
  data() {
    return {
      aboutUs: {}
    };
  },
  created() {
    getArticleById("1703695355453743106").then(res=>{
      this.aboutUs = res.data.data;
    });
  }
}
</script>

<style scoped>

</style>
