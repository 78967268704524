<template>
    <iframe class="small" :src='src' @load="vrLoad" frameborder='no'
            :style="'width:100%;height:'+bannerHeight+'px;'"></iframe>
</template>

<script>
    import {getImagesById} from "@/api/images";

    export default {
        name: "Vr",
        data() {
            return {
                bannerHeight: 0,
                src: null
            }
        },
        created() {
            getImagesById("1646429098983923714").then(res => {
                let url = res.data.data.url;
                if (url) {
                    console.log(url)
                    this.src = url;
                }
            });
        },
        methods: {
            vrLoad() {
                this.$nextTick(() => {
                    let app=document.getElementById("bd");
                    app.style.overflowY='hidden';
                    let fullHeight = window.document.body.offsetHeight-2;
                    this.bannerHeight = fullHeight

                })
            }
        }
    }
</script>

<style scoped>

</style>

