<template>
  <div class="sub-nav-inner">
    <div class="con w1440 clearfix">
      <div class="left" style="font-size: 0.9rem;">
        <a href="/">
          <span class="iconfont">&#xe622;</span>
        </a>
        <span class="iconfont">&#xe683;</span>
        <a :href="path">{{ currentPage }}</a>
        <!--                <span class="iconfont">&#xe683;</span>-->
        <div v-if="path.indexOf('about')===1||path.indexOf('honor')===1||path.indexOf('team')===1||path.indexOf('album')===1||path.indexOf('introduce')===1"
             class="right swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-ios">
          <ul class="clearfix swiper-wrapper">
            <li :class="path.replaceAll('/','')==='introduce'?'active':''" class="swiper-slide">
              <a :href="'/introduce'">公司介绍</a></li>
            <li :class="path.replaceAll('/','')==='honor'?'active':''" class="swiper-slide">
              <a :href="'/honor'">企业荣誉</a></li>
            <li :class="path.replaceAll('/','')==='team'?'active':''" class="swiper-slide">
              <a :href="'/team'">团队风采</a></li>
              <li :class="path.replaceAll('/','')==='album'?'active':''" class="swiper-slide">
              <a :href="'/album'">钢友画册</a></li>
  <!--            <li :class="path.replaceAll('/','')==='about'?'active':''" class="swiper-slide swiper-slide-active"-->
  <!--               style="width: 120px;"><a :href="'/about'">关于我们</a>-->
  <!--            </li>-->
          </ul>
          <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
        </div>


        <!--物流公司-->
        <div v-if="path.indexOf('logisticsintroduce')===1"
             class="right swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-ios">
          <ul class="clearfix swiper-wrapper">
            <li :class="path.replaceAll('/','')==='logisticsintroduce'?'active':''" class="swiper-slide" style="width: 120px;">
              <a :href="'/logisticsintroduce'">公司介绍</a></li>
<!--            <li :class="path.replaceAll('/','')==='honor'?'active':''" class="swiper-slide" style="width: 120px;">-->
<!--              <a :href="'/honor'">企业荣誉</a></li>-->
<!--            <li :class="path.replaceAll('/','')==='team'?'active':''" class="swiper-slide" style="width: 120px;">-->
<!--              <a :href="'/team'">团队风采</a></li>-->
            <!--            <li :class="path.replaceAll('/','')==='about'?'active':''" class="swiper-slide swiper-slide-active"-->
            <!--               style="width: 120px;"><a :href="'/about'">关于我们</a>-->
            <!--            </li>-->
          </ul>
          <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
        </div>

        <!--生产加工模块-->
        <div v-if="path.indexOf('produce')===1"
             class="right swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-ios">
          <ul class="clearfix swiper-wrapper">
            <li :class="path.replaceAll('/','')==='produce'?'active':''" class="swiper-slide" style="width: 120px;">
              <a :href="'/produce'">加工生产</a></li>
            <!--            <li :class="path.replaceAll('/','')==='honor'?'active':''" class="swiper-slide" style="width: 120px;">-->
            <!--              <a :href="'/honor'">企业荣誉</a></li>-->
            <!--            <li :class="path.replaceAll('/','')==='team'?'active':''" class="swiper-slide" style="width: 120px;">-->
            <!--              <a :href="'/team'">团队风采</a></li>-->
            <!--            <li :class="path.replaceAll('/','')==='about'?'active':''" class="swiper-slide swiper-slide-active"-->
            <!--               style="width: 120px;"><a :href="'/about'">关于我们</a>-->
            <!--            </li>-->
          </ul>
          <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
        </div>

        <div  v-if="path.indexOf('news')===1||path.indexOf('industry')===1||path.indexOf('notice')===1||path.indexOf('video')===1"
           class="right swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-ios">
          <ul class="clearfix swiper-wrapper">
            <li :class="path.replaceAll('/','')==='news'?'active':''" class="swiper-slide swiper-slide-active customWidth"
               ><a :href="'/news'">钢友新闻</a></li>
            <li :class="path.replaceAll('/','')==='industry'?'active':''" class="swiper-slide customWidth">
              <a :href="'/industry'">行业新闻</a></li>
            <li :class="path.replaceAll('/','')==='notice'?'active':''" class="swiper-slide customWidth">
              <a :href="'/notice'">通知公告</a></li>
            <li :class="path.replaceAll('/','')==='video'?'active':''" class="swiper-slide customWidth">
              <a :href="'/video'">媒体中心</a></li>

          </ul>
          <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
        </div>


        <div  v-if="path.indexOf('product')===1||path.indexOf('photovoltaic')===1||path.indexOf('steelstructure')===1||path.indexOf('weldedpipe')===1"
               class="right swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-ios">
          <ul class="clearfix swiper-wrapper">
            <li :class="path.replaceAll('/','')==='product'?'active':''" class="swiper-slide swiper-slide-active "
            ><a :href="'/product'">产品中心</a></li>
            <li :class="path.replaceAll('/','')==='weldedpipe'?'active':''" class="swiper-slide ">
              <a :href="'/weldedpipe'">钢管生产</a></li>
            <li :class="path.replaceAll('/','')==='photovoltaic'?'active':''" class="swiper-slide ">
              <a :href="'/photovoltaic'">光伏支架</a></li>
            <li :class="path.replaceAll('/','')==='steelstructure'?'active':''" class="swiper-slide">
              <a :href="'/steelstructure'">钢结构</a></li>
          </ul>
          <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
        </div>

<!--`        <div v-if="path.indexOf('product')===1||path.indexOf('photovoltaic')===1||path.indexOf('steelstructure')===1||path.indexOf('weldedpipe')===1"-->
<!--             style="border: 1px solid red;overflow-x: scroll!important;"    class="right swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-ios">-->
<!--          <ul class="clearfix swiper-wrapper">-->
<!--            <li :class="path.replaceAll('/','')==='product'?'active':''" class="swiper-slide" style="width: 120px;">-->
<!--              <a :href="'/product'">产品中心</a>-->
<!--            </li>-->
<!--            <li :class="path.replaceAll('/','')==='weldedpipe'?'active':''" class="swiper-slide" style="width: 120px;">-->
<!--              <a :href="'/weldedpipe'">焊管</a>-->
<!--            </li>-->
<!--            <li :class="path.replaceAll('/','')==='photovoltaic'?'active':''" class="swiper-slide" style="width: 120px;">-->
<!--              <a :href="'/photovoltaic'">光伏支架</a>-->
<!--            </li>-->
<!--            <li :class="path.replaceAll('/','')==='steelstructure'?'active':''" class="swiper-slide" style="width: 120px;">-->
<!--              <a :href="'/steelstructure'">钢结构</a>-->
<!--            </li>-->

<!--          </ul>-->
<!--          <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>-->
<!--        </div>`-->


        <!--        <div v-if="path.indexOf('party')===1||path.indexOf('dynamic')===1||path.indexOf('group')===1"-->
        <!--             class="right swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-ios">-->
        <!--          <ul class="clearfix swiper-wrapper">-->
        <!--            <li :class="path.replaceAll('/','')==='dynamic'?'active':''" class="swiper-slide swiper-slide-active"-->
        <!--                style="width: 120px;"><a :href="'/dynamic'">党建动态</a></li>-->
        <!--            <li :class="path.replaceAll('/','')==='group'?'active':''" class="swiper-slide" style="width: 120px;">-->
        <!--              <a :href="'/group'">党组文化</a></li>-->
        <!--          </ul>-->
        <!--          <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Bread",
  props: {
    jupPage: {
      type: String,
      default: ''
    },
    jupPath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentPage: this.jupPage || "/",
      path: this.jupPath || "首页",

    }
  },
  methods: {
    handleLi() {
      return this.path.indexOf('news') === 1 || this.path.indexOf('industry') === 1 || this.path.indexOf('notice') === 1;
    },
  }
}
</script>

<style scoped>
.customWidth {
  width: 20%!important;
}

</style>
