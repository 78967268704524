<template>
  <div>
    <banner choose-int="1506062086005678082"></banner>
    <bread :jup-path="'/'+pagePath" :jup-page="pageTitle"></bread>
    <div class="product">
      <div class="con w1440">
          <div class="wow fadeInUp slow animated" style="visibility: visible; animation-name: fadeInUp;">
              <!--          <h2>{{ aboutUs.title }}</h2>-->
              <div class="text">
                  <div id="fix" v-html="aboutUs.content"></div>
              </div>
          </div>
      </div>
    </div>
    <customFooter></customFooter>
  </div>
</template>

<script>
import customFooter from "@/components/footer/footer";
import Bread from "@/components/bread/Bread";
import Banner from "@/components/banner/Banner";
import {getArticleById} from "@/api/article";
export default {
  name: "PhotovoltaicBracket",
  components: {
    customFooter,
    Bread,
    Banner
  },
  data() {
    return {
      pagePath: 'photovoltaic',
      pageTitle: '光伏支架',
      aboutUs: {}
    };
  },
    created() {
        getArticleById("1656587182909792257").then(res => {
           // console.log("这里"+JSON.stringify(res))
            this.aboutUs = res.data.data;
        });
    }
}
</script>

<style scoped>

</style>
